@import url(https://fonts.googleapis.com/css2?family=Oswald:wght@500&display=swap);
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
.container {
  max-width: 768px;
  background-color: #F9F8F4;
  min-height: 700px;
  height: 100%;
}

#header {
  height: 50px;
  width: 100%;
  background-color: #14213D;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #FCA311;
  font-family: Oswald, sans-serif;
  font-size: 30px;
  font-weight: 700;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

#hero {
  width: 100%;
}

#headshot {
  border-radius: 30px;
  width: 80%;
}

#about-section {
  font-family: Roboto, sans-serif;
  font-size: 18px;
  margin: 10px 0 30px 0;
}

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 60px;
}
.wrapper .title {
  margin: 10px 0 0 0;
  font-size: 24px;
  font-weight: 700;
  font-family: Oswald, sans-serif;
}
.wrapper img {
  width: 90%;
  margin: 20px 0;
  border-radius: 5px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.wrapper .text-block {
  height: 250px;
  width: 100%;
  background-color: #14213D;
  color: #FCA311;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Roboto, sans-serif;
  font-size: 30px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.wrapper button {
  width: 100px;
}
.wrapper #request-quote {
  width: 225PX;
  height: 100px;
  background-color: #FCA311;
  margin: 20px 0 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: Oswald, sans-serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 25px;
  border-radius: 20px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

#first-block {
  margin-top: -5px;
}

#quoteForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  padding: 15px;
}
#quoteForm .errors {
  margin: 10px 0;
}
#quoteForm label {
  display: block;
}
#quoteForm input, #quoteForm textarea {
  display: block;
  font-family: Roboto, sans-serif;
  margin: 10px 0;
  padding: 5px;
  width: 100%;
  height: 56px;
  border-radius: 4px;
  position: relative;
  background-color: rgba(255, 255, 255, 0.3);
  transition: 0.3s all;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
#quoteForm input :hover, #quoteForm textarea :hover {
  background-color: rgba(255, 255, 255, 0.45);
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);
}
#quoteForm .error-message {
  font-family: Roboto, sans-serif;
}
#quoteForm .single-line {
  height: 24px;
}
#quoteForm #submit-button {
  width: 50%;
  height: 50px;
  margin: 20px 0 50px 0;
  border-radius: 10px;
  border: 2px solid #FCA311;
  font-family: Oswald, sans-serif;
  font-size: 18px;
}

#footer {
  height: 60px;
  max-width: 768px;
  width: 100%;
  background-color: #FFFFFF;
  position: fixed;
  bottom: 0;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  display: flex;
  justify-content: space-evenly;
}
#footer a {
  display: flex;
  align-items: center;
}
#footer .footerButton {
  height: 85%;
}
#footer .extraMargin {
  margin: 0 15px 0 15px;
}

@media (min-width: 768px) {
  #header:hover, #request-quote {
    cursor: pointer;
  }
}

/*# sourceMappingURL=App.css.map */

